$(document).ready(function () {

  // change files url
  function changeUrl(tagName, attr) {

    for (var i = 0; i < document.getElementsByTagName(tagName).length; i++) {
      var url = document.getElementsByTagName(tagName)[i].getAttribute(attr);
      if (url && url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
        document.getElementsByTagName(tagName)[i][attr] = window.ngtheme + url;
      }
    }
  }

  changeUrl('img', 'src');
  changeUrl('script', 'src');
  changeUrl('link', 'href');

  // carousel

  $(".owl-carousel").owlCarousel({
    loop: false,
    margin: 0,
    nav: true,
    items: 1,
    navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
    dots: true
  });

  // modal

  $('.open-modal').click(function (e) {
    e.preventDefault();
    $($(this).attr('href')).addClass('active');
  });

  $('.close').click(function (e) {
    e.preventDefault();
    $('.modal').removeClass('active');
  });

  $('.modal').click(function (e) {
    if ($(e.target).hasClass('modal') && $(e.target).hasClass('active')) {
      $(e.target).removeClass('active')
    }
  });

  // toggle faq item

  $('.open-faq-item').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    if ($(this).hasClass('active')) {
      $(this).find('i').attr('class', 'fas fa-minus');
    } else {
      $(this).find('i').attr('class', 'fas fa-plus');
    }
  })

  // tour-wrap toggle
  $('.open-tour').click(function (e) {
    $('.tour').addClass('active')
  })

  $(document).click(function (event) {
    var $target = $(event.target);
    var $openTour = $target.closest('.open-tour')[0];

    if (!$target.closest('#tour').length &&
      $('.tour').hasClass('active') &&
      !($openTour && $openTour.className === 'open-tour')) {
      $('.tour').removeClass('active');
    }
  });
  $('.close-tour').click(function (e) {
    e.preventDefault();
    $('.tour').removeClass('active');
  })

  // tabs 

  $('.tab-menu').click(function (e) {
    e.preventDefault();
    $('.tab-menu').removeClass('active');
    $(this).addClass('active');
    $(this).parents('.tabs').find('.tab-content').removeClass('active');
    $($(this).attr('href')).addClass('active');
  })

  // mobile menu

  $('.open-menu').click(function (e) {
    e.preventDefault();
    $('.menu-content').addClass('active');
  });

  $('.close-menu').click(function (e) {
    e.preventDefault();
    $('.menu-content').removeClass('active');
  });

  // fancybox
$(document).ready(function() {
  $(".gallery-img a").fancybox();
  
})
  $(document).ready(function () {
    $(".fancybox").fancybox({
      width: 768,
      buttons: [
        "close"
      ],
    
    })
  });

});

// animate scroll


$(".scroll").click(function (event) {
  event.preventDefault();
  var id = $(this).attr('href'),
    top = $(id).offset().top - 200;
  $('body,html').animate({ scrollTop: top }, 1500);
});


$(document).scroll(function (event) {
  if ($(document).scrollTop() > 100) {
    console.log('123');
    $('.scroll-link').removeClass('d-none');
  } else {
    $('.scroll-link').addClass('d-none');
  };
});